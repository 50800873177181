.pace {
	-webkit-pointer-events: none;
	pointer-events: none;

	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;

	overflow: hidden;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99999;
	width: 100%;
	height: 3px;
	background: #fff;
}

.pace-inactive {
	display: none;
}

$backgroundColor: var(--background-color);
.pace .pace-progress {
	background-color: $backgroundColor;
	position: fixed;
	top: 0;
	bottom: 0;
	right: 100%;
	width: 100%;
	overflow: hidden;
	height: 3px;
}

.pace .pace-activity {
	position: fixed;
	top: 0;
	right: -32px;
	bottom: 0;
	left: 0;
	height: 3px;

	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0);
	-o-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0);

	background-image: -webkit-gradient(linear, 0 100%, 100% 0, color-stop(0.25, rgba(255, 255, 255, 0.2)), color-stop(0.25, transparent), color-stop(0.5, transparent), color-stop(0.5, rgba(255, 255, 255, 0.2)), color-stop(0.75, rgba(255, 255, 255, 0.2)), color-stop(0.75, transparent), to(transparent));
	background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
	background-image: -moz-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
	background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
	background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.2) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.2) 50%, rgba(255, 255, 255, 0.2) 75%, transparent 75%, transparent);
	-webkit-background-size: 32px 32px;
	-moz-background-size: 32px 32px;
	-o-background-size: 32px 32px;
	background-size: 32px 32px;

	-webkit-animation: pace-theme-barber-shop-motion 500ms linear infinite;
	-moz-animation: pace-theme-barber-shop-motion 500ms linear infinite;
	-ms-animation: pace-theme-barber-shop-motion 500ms linear infinite;
	-o-animation: pace-theme-barber-shop-motion 500ms linear infinite;
	animation: pace-theme-barber-shop-motion 500ms linear infinite;
}

@-webkit-keyframes pace-theme-barber-shop-motion {
	0% { -webkit-transform: none; transform: none; }
	100% { -webkit-transform: translate(-32px, 0); transform: translate(-32px, 0); }
}
@-moz-keyframes pace-theme-barber-shop-motion {
	0% { -moz-transform: none; transform: none; }
	100% { -moz-transform: translate(-32px, 0); transform: translate(-32px, 0); }
}
@-o-keyframes pace-theme-barber-shop-motion {
	0% { -o-transform: none; transform: none; }
	100% { -o-transform: translate(-32px, 0); transform: translate(-32px, 0); }
}
@-ms-keyframes pace-theme-barber-shop-motion {
	0% { -ms-transform: none; transform: none; }
	100% { -ms-transform: translate(-32px, 0); transform: translate(-32px, 0); }
}
@keyframes pace-theme-barber-shop-motion {
	0% { transform: none; transform: none; }
	100% { transform: translate(-32px, 0); transform: translate(-32px, 0); }
}