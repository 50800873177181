


::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background: gray;
}

$backgroundColor: var(--background-color);
::-webkit-scrollbar-thumb {
  background-color: $backgroundColor;
}


::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}